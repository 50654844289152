<template>
  <ion-page class="page bg-transparent mt-4">
    <div class="pt-3 py-2">
      <div class="d-flex justify-content-between align-items-center flex-wrap">
        <div class="title">Raffles</div>

        <div class="d-flex ml-auto flex-wrap">
          <ion-button color="transparent" class="manage-btn" @click="manageRaffle">
            <i class="ti-pencil-alt mx-1" />
            Manage Raffles</ion-button
          >
          <ion-button class="submit-btn" @click="submitRaffle">Submit A Raffle</ion-button>
        </div>
      </div>
      <p>
        Make a raffle to make somebody's day and earn the "Raffle Host" and "Raffle Maestro" achievements for your
        profile!
      </p>

      <!-- raffle winners bar -->

      <div v-if="raffleWinners && raffleWinners.length" class="auto-scroll-container">
        <div class="auto-scroll-content">
          <p v-for="(winner, index) in raffleWinners" :key="index" class="text">
            <i class="ti-star icon mx-1" /> Congrats <strong>@{{ get(winner, 'user.username') }}</strong> on winning
            <strong>"{{ get(winner, 'raffle.title') }}"</strong> raffle! <i class="ti-star icon" />
          </p>
        </div>
      </div>

      <raffle-tabs class="mt-2" :tabs="tabs" :active-index="tabIndex" @tab-changed="tabChanged" />

      <!-- featured giveaways -->

      <div v-if="featuredRaff && featuredRaff.length" class="container mt-3">
        <div class="d-flex flex-wrap justify-content-center">
          <horizontal-raffles
            v-for="giveaway in featuredRaff.slice(0, 2)"
            :key="`featured-raf-${giveaway.id}`"
            :raffle="giveaway"
            :entry-status="!!get(mergedEntries, `${giveaway.id}`)"
            :hide-mobile-margin="true"
            :is-featured="true"
          />
        </div>
      </div>

      <div class="wrapper d-flex">
        <div v-if="mergedRaff && mergedRaff.length" class="raffles w-100 mt-3">
          <div class="d-flex raffle">
            <div class="d-flex pb-4 card-container flex-wrap w-100">
              <horizontal-raffles
                v-for="raffle in mergedRaff"
                :key="`raffle-${raffle.id}`"
                :raffle="raffle"
                :entry-status="!!get(mergedEntries, `${raffle.id}`)"
                :hide-mobile-margin="true"
              />
            </div>
          </div>
          <p
            v-if="nextPageExists"
            class="clickable-item-hov d-flex justify-content-center align-items-center text-black"
            @click="requestLoadMore"
          >
            <span v-if="!isLoading"> Load more </span>
          </p>
        </div>

        <div v-else-if="!isLoading && !get(featuredRaff, 'length')" class="no-data">No Ongoing Raffles</div>

        <ChLoading size="sm" v-if="isLoading" class="spinner mx-auto mt-3" />
      </div>
    </div>
  </ion-page>
</template>

<script lang="ts" setup>
import RaffleTabs from './components/raffle-tabs.vue';
import { raffleTabs } from '@/shared/statics/tabs';
import { Tab } from '@/shared/types/static-types';
import HorizontalRaffles from '@/shared/components/HorizontalRaffles.vue';
import { getRaffles, getRaffleWinners, getRafflesEntryStatus } from '@/shared/actions/raffles';

import constants from '@/shared/statics/constants';
import { getUserByUsername } from '@/shared/actions/users';

const categoriesByVal = keyBy(constants.raffleCategories, 'value');
const currentTab = ref('all');
const paging = ref({});
const router = useRouter();

const tabs = ref(raffleTabs);
const tabIndex = ref(0);
const currPage: any = ref(1);
const featuredRaff = ref([]);
const animationDuration = ref('25s');
const raffles: any = ref([]);
const raffleWinners: any = ref([]);

const isLoading = ref(true);

const entriesStats = ref({});
const featuredEntriesStat = ref({});

const filters = ref({
  category: '',
  user: '',
});

const nextPageExists = computed(() => {
  return !!get(paging.value, 'next');
});

const submitRaffle = () => {
  router.push({ name: 'create-raffle' });
};
const manageRaffle = () => {
  router.push({ name: 'manage-raffle' });
};

const tabChanged = ({ value }: Tab) => {
  currentTab.value = value;
  router.replace({ name: 'raffles', query: { tab: value } });
};

watch(currentTab, async () => {
  raffles.value = [];
  featuredRaff.value = [];
  filters.value = { user: '', category: '' };

  if (currentTab.value === 'all') {
    fetchRaffles({ page: 1, ordering: 'end_date', is_featured: false });
    featuredRaffles();
  } else if (currentTab.value === 'characterhub') {
    filters.value.user = 'characterhub';
    fetchRaffles({ page: 1, ordering: 'end_date' });
  } else if (currentTab.value === 'character') {
    filters.value.category = 'character_giveaway,ych';
    fetchRaffles({ page: 1, ordering: 'end_date' });
  } else {
    filters.value.category = 'custom_art,art_base,animation';
    fetchRaffles({ page: 1, ordering: 'end_date' });
  }
});

const fetchWinners = async () => {
  raffleWinners.value = await getRaffleWinners();
};

const mergedRaff: any = computed(() => {
  const mergedArray = [...featuredRaff.value.slice(2), ...raffles.value];
  return mergedArray;
});

const mergedEntries: any = computed(() => {
  const mergedArray = { ...featuredEntriesStat.value, ...entriesStats.value };
  return mergedArray;
});

const winnersAnimDuration = computed(() => {
  return `${(get(raffleWinners.value, 'length') || 0) * 6}s`;
});

const fetchEntryStatuses = async (results: [], reset = false, is_featured = false) => {
  const resp = await getRafflesEntryStatus(map(results, 'id'));
  if (!is_featured) {
    entriesStats.value = reset ? keyBy(resp, 'raffle') : { ...entriesStats.value, ...keyBy(resp, 'raffle') };
  } else {
    featuredEntriesStat.value = keyBy(resp, 'raffle');
  }
};

const fetchRaffles = async (params: { page?: number; ordering?: string; is_featured?: boolean | null }) => {
  isLoading.value = true;
  try {
    let user;
    if (filters.value.user) {
      try {
        user = await getUserByUsername(filters.value.user);
      } catch (_e) {
        isLoading.value = false;
        return;
      }
    }

    const { results, ...pagingRes } = await getRaffles({
      ...params,
      page_size: 39,
      ...(filters.value.category && { category: filters.value.category }),
      ...(user && { user: user.id }),
    });
    fetchEntryStatuses(results, params.page == 1, false);
    raffles.value = params.page == 1 ? results : raffles.value.concat(results);
    paging.value = pagingRes;
    currPage.value = params.page;
  } finally {
    isLoading.value = false;
  }
};

const featuredRaffles = async () => {
  const results = await getRaffles({ ordering: 'end_date', is_featured: true });
  featuredRaff.value = results;
  fetchEntryStatuses(results, false, true);
};

const requestLoadMore = async (ev: MouseEvent) => {
  if (!paging.value.next) {
    (ev?.target as any).complete();
  } else {
    fetchRaffles({
      page: currPage.value + 1,
      ordering: 'end_date',
      is_featured: currentTab.value === 'all' ? false : null,
    });
  }
};

onBeforeMount(() => {
  const tIndex = indexOf(
    tabs.value,
    find(tabs.value, (tab: any) => tab.value === router.currentRoute.value.query.tab)
  );
  if (router.currentRoute.value.query.tab && tIndex !== -1) {
    tabIndex.value = tIndex;
    currentTab.value = router.currentRoute.value.query.tab;
  }
});

onMounted(async () => {
  if (currentTab.value === 'all') featuredRaffles();
  fetchRaffles({ page: 1, ordering: 'end_date', is_featured: currentTab.value === 'all' ? false : null });
  fetchWinners();
});
</script>

<style lang="sass" scoped>
.page
  position: relative
  top: -20px

.manage-btn
  color: var(--ion-color-primary)
  font-weight: bold
  text-transform: none
  height: 32px

.raffles
  overflow-x: auto
.icon
  color: #FF9C33
.dark .icon
  color: #FFDE67
.raffle
  overflow-x: hidden
  -ms-overflow-style: none
  scrollbar-width: none
  &::-webkit-scrollbar
    display: none

.card-container
  margin: 0 auto
  justify-content: center
.text
  color: #214163
.dark .text
  color: #000C19
.submit-btn
  --border-radius: 20px
  text-transform: none
  font-weight: bold
  height: 32px

.title
  font-size: 24px
  font-weight: bold

.auto-scroll-container
  max-width: 100%
  overflow: hidden
  position: relative
  border-radius: 10px
  border: solid #FFE455 3px
  background: white

.dark .auto-scroll-container
  background: linear-gradient(to right, #FFBC3A 9%, #FFBC3A 17%, #E28937 98%, #E28937 100%) !important
  border: 0px solid !important
.auto-scroll-content
  white-space: nowrap
  overflow: hidden
  display: inline-block
  animation: auto-scroll-content v-bind(winnersAnimDuration) linear infinite

  & p
    display: inline-block
    margin-right: 15px
    margin-top: 15px

@keyframes auto-scroll-content
  0%
    transform: translate(0, 0)

  100%
    transform: translate(-70%, 0)

.spinner
  width: 25px
  height: 25px
</style>
